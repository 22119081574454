// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/common/Animations/Animations.ts"
);
import.meta.hot.lastModified = "1731188936864.5095";
}
// REMIX HMR END

import { keyframes } from "@emotion/react";

export const fadeIn = keyframes`
0% {
    opacity: 0;
   }
100% {
    opacity: 1;
   }
`;

export const beating = keyframes`
0% {
    transform: scale(1);
   }
50% {
    transform: scale(1.05);
   }
100% {
    transform: scale(1);
   }
`;
